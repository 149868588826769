import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import ArrowDownIcon from 'assets/icons/arrow-drop-down-down.svg';
import ArrowRightIcon from 'assets/icons/arrow-drop-down-right.svg';
import { tablet } from 'styles/breakpoints';

import { DynamicImage, PrimaryButton, Text } from 'components';

import Layout from 'layouts/Layout';
import { useRouter } from 'apis/history';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
`;

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 1rem 4.5rem;
  width: 1204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 0 1rem 3rem;
    height: unset;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 0.5rem 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e4e4eb;
  justify-content: space-between;
  @media ${tablet} {
    padding-bottom: 1rem;
  }
`;

const Title = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  color: #28293d;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: normal;
  }
`;

const List = styled.div`
  margin-top: 3rem;
  max-width: 100%;
  width: 832px;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 0.75rem;
  }

  @media ${tablet} {
    div:nth-child(1n):not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowUp = styled(ArrowRightIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const Answer = styled(Text)`
  padding-bottom: 1.5rem;
  color: #28293d;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;
const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.75rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  div {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media ${tablet} {
    min-width: 7.06rem;
    min-height: unset;
  }
`;
const FAQ: FC<PeopleAskUsProps> = () => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);
  const { goToLanding } = useRouter();
  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const isExpanded = (index: number) => expandedQuestions.includes(index);

  return (
    <Layout
      seoTitle="FAQ"
      seoName="FAQ"
      seoDescription="Find out more about Welcome Baby"
      logoVariant={'left'}
      hasDivider={true}
      rightComponent={
        <Button onClick={() => goToLanding()}>Start the quiz</Button>
      }
    >
      <Wrapper>
        <Container>
          <Title>Frequently asked questions</Title>
          <List>
            <QuestionContainer expanded={isExpanded(1)}>
              <TitleContainer onClick={() => handleClick(1)}>
                <Question>
                  How long does it take for my book to arrive?
                </Question>
                {isExpanded(1) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(1)}>
                <div ref={childRef}>
                  <Answer>
                    Your ebook will reach your inbox within 24 hours. Please
                    allow 8-16 business days for the paperback to arrive.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(2)}>
              <TitleContainer onClick={() => handleClick(2)}>
                <Question>What happens after I order?</Question>
                {isExpanded(2) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(2)}>
                <div ref={childRef}>
                  <Answer>
                    You&apos;ve made a wonderful choice! Right after you place
                    your order, we will begin customizing your perfect
                    &apos;Welcome Baby&apos; book, tailoring it specifically to
                    your needs and preferences. We consider everything that
                    matters to you and your baby, put it all into your
                    personalized book, and then print and ship it directly to
                    you.
                  </Answer>
                  <Answer>
                    It&apos;s not just any book – it&apos;s personalized just
                    for your family, with a baby growth and milestones tracker,
                    development activity ideas, parenting tips, and, of course,
                    photo and journaling spaces to help you capture the
                    wonderful moments together.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(3)}>
              <TitleContainer onClick={() => handleClick(3)}>
                <Question>How is my book personalized?</Question>
                {isExpanded(3) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(3)}>
                <div ref={childRef}>
                  <Answer>
                    Your book is personalized to make it unique and special for
                    your family. You can choose the{' '}
                    <b>color and illustration of the cover</b>, which will also
                    feature the names of the parents and the baby. Inside,
                    you&apos;ll find a <b>custom dedication page</b>
                    (finalized after purchase) for your heartfelt message, along
                    with <b>picture and journaling sections</b> to capture your
                    baby&apos;s journey.
                  </Answer>
                  <Answer>
                    The book includes{' '}
                    <b>games and activities for development</b> tailored to your
                    baby&apos;s traits and interests, as well as{' '}
                    <b>personalized parenting tips. Milestones</b> are based on
                    your baby&apos;s age and developmental stage, helping you
                    track and celebrate their growth and achievements.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(4)}>
              <TitleContainer onClick={() => handleClick(4)}>
                <Question>Is this book right for me?</Question>
                {isExpanded(4) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(4)}>
                <div ref={childRef}>
                  <Answer>
                    Welcome Baby is perfect for every family, whether they are
                    parents-to-be, new parents, or parents with multiple
                    children. The book includes:
                  </Answer>
                  <Answer>
                    <ul>
                      <li>
                        <b>Journaling pages</b> to track the happiest memories
                        together
                      </li>
                      <li>
                        <b>Parent-baby bonding tips</b> and engaging activities
                      </li>
                      <li>
                        Baby&apos;s <b>growth and milestone tracker</b>
                      </li>
                      <li>
                        <b>Activities for baby development</b> based on their
                        age and interests
                      </li>
                    </ul>
                  </Answer>
                  <Answer>
                    This and much more to address your family&apos;s specific
                    needs and goals, helping you create a wonderful book full of
                    memories that last a lifetime.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(5)}>
              <TitleContainer onClick={() => handleClick(5)}>
                <Question>
                  I&apos;m a first-time parent. Does the book include the
                  information I need for parenting?
                </Question>
                {isExpanded(5) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(5)}>
                <div ref={childRef}>
                  <Answer>
                    Yes! Our book is ideal for first-time parents, as well as
                    parents-to-be or parents of multiple children. Your book is
                    tailored specifically to your family&apos;s needs and
                    preferences to make your parenting journey as peaceful,
                    smooth, and joyful as possible.
                  </Answer>
                  <Answer>
                    The book is full of journaling and photo spaces to help you
                    create a story full of love and precious memories with your
                    baby. On top of that, you will find answers to questions
                    about your baby&apos;s growth and development.
                  </Answer>
                  <Answer>
                    The book is personalized based on your quiz answers to make
                    sure it fits your needs and deepest desires for your baby.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
          </List>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default React.memo(FAQ);

const StyledImage = styled(DynamicImage)``;

const UnderscoreLink = styled.a`
  text-decoration: underline !important;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding-top: 4.5rem;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 416px);
  height: 100%;
  @media ${tablet} {
    z-index: 0;
    padding-top: 1.5rem;
  }
`;
